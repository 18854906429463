import { basicApi } from "../main";


export default class UserApiService {
	http;

	constructor() {
		this.http = basicApi.http;
	}


	// ******************************************************
    // Retrieve the list of all users
	async getUserList() {
		var retData = await this.http.get('/api/user/list')
			.catch( function (error) { console.log(error.message); throw(error); } );
		if (Object.keys(retData).length === 0)
			return [];

		return retData.data.list;
	}


	// ******************************************************
	// Creating a new user
	async userCreation(addUserRequest) {
		var retData = await this.http.post('/api/user', addUserRequest)
			.catch( function (error) { console.log(error.message); throw(error); } );

		return (retData.status == 200) ? true : false;
	}


	// ******************************************************
	// Delete user by id
	async deleteUser(id) {
		var retData = await this.http.delete('/api/user/' + id)
			.catch( function (error) { console.log(error.message); throw(error); } );

		return (retData.status == 200) ? true : false;
	}


	// ******************************************************
	// Edit user by id
	async editUser(editUserRequest, id = null) {
		var retData = await this.http.put((id == null) ? '/api/user' : '/api/user/' + id, editUserRequest)
			.catch( function (error) { console.log(error.message); throw(error); } );

		return (retData.status == 200) ? true : false;
	}
}