<template>
	<router-view/>
</template>

<script setup>
	import { watch } from 'vue'
	import { showNotification } from "@/main";
	import ToastNotification from "@/helpers/toastNotification";


	// **************************************************
	// Determines when to show the error message
	watch(() => showNotification.value, (newValue, oldValue) => {
		// Show toast when showNotification.value is true
		if (showNotification.value == true) {
			ToastNotification.showErrorNotification("Si è verificato un errore!");	// Generic error message
			showNotification.value = false;											// Reset variable value
		}
	});
</script>

<style>
</style>
