import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import UserPage from "@/pages/UserPage.vue";
import StorageHelper from "@/helpers/storageHelper";
import PodcastPlayer from "../pages/PodcastPlayer.vue";

const router = createRouter({
	history: createWebHistory(),
	routes: [
        {
            path: "/",
            name: "HomePage",
            component: HomePage,
            meta: {
                auth: true
            }
        },
        {
            path: "/category/:id",
            name: "SubCategory",
            component: HomePage,
            meta: {
                auth: true
            }
        },
        {
            path: "/login",
            name: "Login",
            component: LoginPage,
        },
        {
            path: "/user/list",
            name: "UserList",
            component: UserPage,
        },
        {
            path: "/podcast/player/:id",
            name: "PodcastPlayer",
            component: PodcastPlayer,
        },
        { path: '/:pathMatch(.*)*', redirect: '/' }
	]
});

// **** GLOBAL GUARDS ****
router.beforeEach((to, from) => {
	if (to.meta.auth == true && !StorageHelper.isAuth())
		return '/login';
    if (to.path == '/user/list' && !StorageHelper.isAuth()) {
        return '/login';
    }

    var authRoutes = ['/login'];
	if (from.path == '/' && from.name !== undefined && authRoutes.includes(to.path) && StorageHelper.isAuth()) {
		return false;
	}
    if (from.name == undefined && authRoutes.includes(to.path) && StorageHelper.isAuth()) {
		return '/';
	}
});

export default router;