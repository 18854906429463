import { BearerName } from "@/config/config";

export default class StorageHelper {


    // **************************************************
    static isAuth() {
        return localStorage.getItem(BearerName) == null ? false : true;
    }

    // **************************************************
    static isAdmin() {
        return localStorage.getItem('role') != 'admin' ? false : true;
    }
}