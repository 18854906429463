import { vueToast } from "@/main";

export default class ToastNotification {


    // **************************************************
    // Show five seconds error notification
    static showErrorNotification(message) {
        vueToast.error(message, {
            position: "top-center",
            timeout: 5000,
            closeButton: false,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: false,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            rtl: false,
        });
    }
}