<script setup>
    import { defineProps, defineEmits } from 'vue';
    import router from '@/router';

    const props = defineProps(['name']);
    const emits = defineEmits(['back']);
</script>


<template>
    <!-- Go to parent category -->
    <button type="button" class="btn btn-outline-dark btn-sm btn button-area" @click="emits('back');" v-if="router.currentRoute.value.fullPath != '/'">
        <i class="bi bi-arrow-90deg-up"></i>
        Back: <span style="font-weight: bold;">{{ name }}</span>
    </button>

    <!-- Manage category and audio  -->
    <div style="min-width: 600px;">
        <!-- Add category -->
        <button type="button" class="btn btn-outline-dark btn-sm btn button-area" data-bs-toggle="modal" data-bs-target="#addCategoryModal">
            <i class="bi bi-folder-plus"></i>
            Crea categoria
        </button>

        <!-- Add audio -->
        <button type="button" class="btn btn-outline-dark btn-sm btn button-area" data-bs-toggle="modal" data-bs-target="#addAudioModal">
            <i class="bi bi-music-note-list"></i>
            Crea audio
        </button>
    </div>
</template>


<style scoped>
    .button-area {
        margin-left: 30px;
        margin-top: 30px;
    }
</style>