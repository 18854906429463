<script setup>
    import { defineProps, ref } from 'vue';
    import OverlayLoader from '@/components/OverlayLoader.vue';
    import CategoryApiService from '@/services/CategoryApiService';
    import AudioApiService from '@/services/AudioApiService';


    // **** VARIABLES ****
    // Api services
    const categoryApiService = new CategoryApiService();
    const audioApiService = new AudioApiService();

    const props = defineProps(['item']);                    // Data from parent component
    const showOverlay = ref(false);                         // Show / Hide overlay with circular loader                  

    
    // **************************************************
    async function onDelete() {
        showOverlay.value = true;
        const retVal = (props.item.category) 
            ? await categoryApiService.deleteCategory(props.item.id) 
            : await audioApiService.deleteAudio(props.item.id);

        if (retVal.status == 200) {
            showOverlay.value = false;
            location.reload();
        }
        else {
            showOverlay.value = false;
            const backdrop = document.querySelector('.modal-backdrop');
            backdrop.classList.remove('modal-backdrop', 'fade', 'show');
        }
    }
</script>

<template>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deleteModalLabel" v-if="props.item && props.item.category">Cancella categoria</h5>
                    <h5 class="modal-title" id="deleteModalLabel" v-if="props.item && !props.item.category">Cancella Audio</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p style="font-weight: bold;" v-if="props.item && props.item.category">Sei sicuro di voler cancellare la categoria "{{ props.item.descr }}"?</p>
                    <p style="font-weight: bold;" v-if="props.item && !props.item.category">Sei sicuro di voler cancellare l'audio "{{ props.item.descr }}"?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" @click="onDelete();">DELETE</button>
                    <button id="close" type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">CHIUDI</button>
                </div>
            </div>
        </div>

        <OverlayLoader v-if="showOverlay"/>
    </div>
</template>


<style></style>