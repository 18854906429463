<script setup>
    import { ref, defineProps } from 'vue';
    import {required } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core';
    import OverlayLoader from '@/components/OverlayLoader.vue';
    import AudioApiService from '@/services/AudioApiService';


    // **** VARIABLES ****
    const audioApiService = new AudioApiService();                                          // Api service
    const props = defineProps(['parentId']);                                                // Data from parent component
    const showOverlay = ref(false);                                                         // Show / Hide overlay with circular loader
    const formData = ref({ id_category: null, descr: null, icon: null, file: null});        // Form data
    const rules = { id_category: {}, descr: { required }, icon: {}, file: { required } };   // FE validation rules
    const v$ = useVuelidate(rules, formData);                                               // Vuelidate validator

    // File input refs
    const audioRef = ref(null);
    const iconRef = ref(null);


    // **** FUNCTIONS ****
    // **************************************************
    // File management: @change event
    function displaySelectedImage(elementId) {
        const selectedImage = document.getElementById(elementId);
        const fileInput = event.target;
        if (fileInput.files && fileInput.files[0]) {
            const reader = new FileReader();
            reader.onload = function(e) { selectedImage.src = e.target.result; };
            reader.readAsDataURL(fileInput.files[0]);
            formData.value.icon = fileInput.files[0];
        }
        else {
            selectedImage.src = require('../assets/empty-icon.png');
        }
    }
    function fileAudioManagement() {
        const fileInput = event.target;
        formData.value.file = (fileInput.files && fileInput.files[0]) ? fileInput.files[0] : null; 
    }


    // **************************************************
    // Add new category
    async function addNewAudio() {
        const isValid = await v$.value.$validate();
        if (isValid) {
            showOverlay.value = true;

            // Set parentId
            const parentId = props.parentId;
            if (parentId)
                formData.value.id_category = parentId;

            // Perform api
            const retVal = await audioApiService.createNewAudio(formData.value);
            if (retVal.status == 200) {
                showOverlay.value = false;
                location.reload();
            }
            else {
                showOverlay.value = false;
                const backdrop = document.querySelector('.modal-backdrop');
                backdrop.classList.remove('modal-backdrop', 'fade', 'show');
            }
        }
    }


    // **************************************************
    // Reset form values on modal close
    function onClose() {
        formData.value.id_category = null;
        formData.value.descr = null;
        formData.value.icon = null;
        formData.value.file = null;
        displaySelectedImage('avatar');
        if (audioRef.value)
            audioRef.value.value = '';
        if (iconRef.value)
            iconRef.value.value = '';
    }
</script>


<template>
    <div class="modal fade" id="addAudioModal" tabindex="-1" aria-labelledby="addAudioModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addAudioModalLabel">Crea audio</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onClose();"></button>
                </div>
                <div class="modal-body">
                    <form class="needs-validation" novalidate @submit.prevent="addNewAudio();">
                        <!-- Description -->
                        <div class="mb-3">
                            <label for="description" class="form-label">Descrizione audio</label>
                            <input type="text" class="form-control" id="description" v-model="formData.descr" aria-describedby="description" 
                                required :class="v$.descr.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire una descrizione</div>
                        </div>

                        <!-- File audio upload -->
                        <div class="mb-3">
                            <label for="formIcon" class="form-label">Seleziona file audio</label>
                            <input class="form-control" type="file" id="formFile" required ref="audioRef"
                                :class="v$.file.$error ? 'is-invalid' : ''" @change="fileAudioManagement()" accept="audio/*">
                            <div class="invalid-feedback">Inserire un file audio</div>
                        </div>

                        <!-- Icon upload -->
                        <div class="row-flex">
                            <div class="column-flex" style="margin-right: 20px;">
                                <img id="avatar" src="@/assets/empty-icon.png" style="width: 50px; height: 50px; object-fit: cover;"/>
                            </div>
                            <div class="mb-3">
                                <label for="formIcon" class="form-label">Seleziona una icona</label>
                                <input class="form-control" type="file" id="formIcon" @change="displaySelectedImage('avatar')" accept="image/*" ref="iconRef">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-outline-primary" @click="addNewAudio();">SALVA</button>
                    <button id="close" type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="onClose();">CHIUDI</button>
                </div>
            </div>
        </div>

        <OverlayLoader v-if="showOverlay"/>
    </div>

</template>


<style scoped>
    .row-flex {
        display: flex;
        flex-direction: row;
    }

    .column-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>