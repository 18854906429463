<script setup>
    import router from '@/router';
    import AuthApiService from '@/services/AuthApiService';
    import StorageHelper from "@/helpers/storageHelper";


    // **** VARIABLES ****
    const authApiService = new AuthApiService();


    // **** FUNCTIONS ****
    // **************************************************
    // User logout and redirection to login page
    async function onLogout() {
        await authApiService.logout().then(() => {
            router.replace({ name: 'Login' });
        });
    }
</script>


<template>
    <div class="dropdown dropstart">
        <i class="bi bi-three-dots-vertical menu" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"></i>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" @click="router.push({ name: 'UserList' });" v-if="StorageHelper.isAdmin()">Lista utenti</a></li>
            <li><a class="dropdown-item" @click="onLogout();">Logout</a></li>
        </ul>
    </div>
</template>


<style scoped>
    .menu {
        color: white;
        cursor: pointer;
        margin-right: 20px;
    }
</style>