import { createApp, ref } from 'vue'
import { useToast } from "vue-toastification";
import App from './App.vue'
import router from './router';
import BasicApiManager from './services/basicApiManager';
import Toast from 'vue-toastification'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'vue-toastification/dist/index.css'

// **** GLOBAL VARS ****
const basicApi = new BasicApiManager();
export { basicApi };

// Toast notification management
const showNotification = ref(false);
const vueToast = useToast();
export { showNotification, vueToast };

const app = createApp(App);
app.use(router);
app.use(Toast);
app.mount('#app');