<script setup>
    import { onBeforeMount, ref } from 'vue';
    import { required, email } from '@vuelidate/validators'
    import UserApiService from '@/services/UserApiService';
    import AppbarDropdown from '@/components/AppbarDropdown.vue';
    import useVuelidate from '@vuelidate/core';
    import DataTable from 'datatables.net-vue3';
    import DataTablesCore from 'datatables.net';

    DataTable.use(DataTablesCore);
    const userApiService = new UserApiService();
    const data = ref(null);
    const selectedItem = ref(null);
    const circularLoader = ref(false);
    const dataTableValues = ref([]);

    // Add user form
    const addUserForm = ref({ name: null, username: null, email: null, password: null, role: 'admin' });
    const addUserRules = { name: {required}, username: {required}, email: {required, email}, password: {required}, role: {required} };
    const v$ = useVuelidate(addUserRules, addUserForm);
    
    // Edit user password
    const editPwdForm = ref({ password: null });
    const editPwdRules = { password: {required} };
    const v2$ = useVuelidate(editPwdRules, editPwdForm);

    // Edit user
    const editUserForm = ref({ name: null, username: null, email: null, role: null });
    const EditUserRules = { name: {required}, username: {required}, email: {required, email}, role: {required} };
    const v3$ = useVuelidate(EditUserRules, editUserForm);

    
    // **************************************************
    onBeforeMount(async () => {
        circularLoader.value = true;
        data.value = await userApiService.getUserList();
        for (var i in data.value) {
            const obj = data.value[i];
            const tmpArray = [data.value[i].id, data.value[i].name, data.value[i].username, data.value[i].role, function (data) { 
                return '' +
                    '<i class="bi bi-pencil icon" @click="' + setFormData(obj) + '" data-bs-toggle="modal" data-bs-target="#editUserModel" style="margin-right: 20px; cursor: pointer;"></i>' + 
                    '<i class="bi bi-key icon" @click="' + setSelectedItem(obj.id) + '" data-bs-toggle="modal" data-bs-target="#editPasswordModal" style="margin-right: 20px; cursor: pointer;"></i>' +
                    '<i class="bi bi-trash icon" @click="' + setSelectedItem(obj.id) + '" data-bs-toggle="modal" data-bs-target="#deleteModal" style="cursor: pointer;"></i>' 
            }];
            dataTableValues.value.push(tmpArray);
        }

        circularLoader.value = false;

        // Edit password dialog: small fix
        const modal = document.getElementById('editPasswordModal');
        modal.addEventListener('keypress', function(e) {
            if (e.key === 'Enter' || e.keyCode === 13)
                e.preventDefault();
        });
    });


    // **************************************************
    async function onCreate() {
        const isValid = await v$.value.$validate();
        if (isValid) {
            var retVal = await userApiService.userCreation(addUserForm.value);
            if (!retVal) {
                const backdrop = document.querySelector('.modal-backdrop');
                backdrop.classList.remove('modal-backdrop', 'fade', 'show');
            }
            else
                location.reload();
        }
    }


    // **************************************************
    function onChange(val) { addUserForm.value.role = val.target.value; }
    function setSelectedItem(id) { selectedItem.value = id; }


    // **************************************************
    // Edit user data
    async function onEdit(id) {
        const isValid = await v3$.value.$validate();
        if (isValid) {
            var retVal = await userApiService.editUser(editUserForm.value, id);
            if (!retVal) {
                const backdrop = document.querySelector('.modal-backdrop');
                backdrop.classList.remove('modal-backdrop', 'fade', 'show');
            }
            else
                location.reload();
        }
    }


    // **************************************************
    // Edit user password
    async function editPwd(id) {
        const isValid = await v2$.value.$validate();
        if (isValid) {
            const data = {
                password: editPwdForm.value.password,
                password_confirmation: editPwdForm.value.password,
            }

            editPwdForm.value['password_confirmation'] = editPwdForm.value.password;

            var retVal = await userApiService.editUser(editPwdForm.value, id);
            if (!retVal) {
                const backdrop = document.querySelector('.modal-backdrop');
                backdrop.classList.remove('modal-backdrop', 'fade', 'show');
            }
            else
                location.reload();
        }
    }


    // **************************************************
    // User delete
    async function onDelete(id) {
        var retVal = await userApiService.deleteUser(id);
        if (!retVal) {
            const backdrop = document.querySelector('.modal-backdrop');
            backdrop.classList.remove('modal-backdrop', 'fade', 'show');
        }
        else   
            location.reload();
    }


    // **************************************************
    // Edit user: form population
    async function setFormData(item) {
        editUserForm.value.name = item.name;
        editUserForm.value.username = item.username;
        editUserForm.value.email = item.email;
        editUserForm.value.role = item.role;
        selectedItem.value = item.id
    }


    // ==== MODALS FORM VALUES RESET ====
    // **************************************************
    function onAddModalClose() {
        addUserForm.value.name = null;
        addUserForm.value.username = null;
        addUserForm.value.email = null;
        addUserForm.value.password = null;
        addUserForm.value.role = 'admin';
    }
    function onEditModalClose() {
        var backup = data.value.find(item => item.id == selectedItem.value);
        if (backup) {
            editUserForm.value.name = backup.name;
            editUserForm.value.username = backup.username;
            editUserForm.value.email = backup.email;
            editUserForm.value.role = backup.role;
        }
    }
    function onEditPwdClose() { editPwdForm.value.password = null; }

</script>


<template>
    <nav class="navbar appbar">
        <div class="container-fluid">
            <a class="navbar-brand appbar-title">
                <img src="@/assets/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
                ROTARY PODCAST
            </a>

            <AppbarDropdown/>
        </div>
    </nav>
    <div style="min-width: 750px;" v-if="!circularLoader">
        <div class="header">
            <div class="center">
                <div style="width: 70%;">
                    <button type="button" class="btn btn-outline-dark" @click="$router.go(-1);" style="margin-right: 10px;">
                        <i class="bi bi-arrow-90deg-up"></i>
                        Back
                    </button>
                    <button type="button btn" class="btn btn-dark" data-bs-toggle="modal" data-bs-target="#addUserModel">Crea nuovo utente</button>
                </div>
            </div>
            <div class="center">
                <h1 style="width: 70%;">LISTA UTENTI</h1>
            </div>
            <div class="center">
                <hr class="hr" style="width: 70%;"/>
            </div>
        </div>

        <!-- User list -->
        <div class="center">
            <DataTable :data="dataTableValues" class="display">
                <thead class="headers">
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Username</th>
                        <th>Ruolo</th>
                        <th>Azioni</th>
                    </tr>
                </thead>
            </DataTable>
        </div>
    </div>
    <div class="spinner-border spinner-center" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
    </div>


    <!-- **** MODALS ****-->
    <!-- Add user modal -->
    <div class="modal fade" id="addUserModel" tabindex="-1" aria-labelledby="addUserModelLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addUserModelLabel">Crea nuovo utente</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onAddModalClose()"></button>
                </div>
                <div class="modal-body">
                    <form class="needs-validation" novalidate>
                        <!-- Role -->
                        <div class="mb-3" style="width: 150px;">
                            <label for="role" class="form-label">Ruolo</label>
                            <select class="form-select" aria-label="role" :class="v$.role.$error ? 'is-invalid' : ''" @change="onChange($event);" v-model="addUserForm.role">
                                <option selected value="admin">admin</option>
                                <option value="user">user</option>
                            </select>
                            <div class="invalid-feedback">Selezionare un ruolo</div>
                        </div>
                        <!-- Name -->
                        <div class="mb-3">
                            <label for="name" class="form-label">Nome</label>
                            <input type="text" class="form-control" id="name" v-model="addUserForm.name" 
                                aria-describedby="name" required :class="v$.name.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire il nome</div>
                        </div>
                        <!-- Username -->
                        <div class="mb-3">
                            <label for="username" class="form-label">Username</label>
                            <input type="text" class="form-control" id="username" v-model="addUserForm.username" 
                                aria-describedby="username" required :class="v$.username.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire la username</div>
                        </div>
                        <!-- Email -->
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" id="email" v-model="addUserForm.email" 
                                aria-describedby="email" required :class="v$.email.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire indirizzo E-mail</div>
                        </div>
                        <!-- Password -->
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input type="text" class="form-control" id="password" v-model="addUserForm.password" 
                                aria-describedby="password" required :class="v$.password.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire la password</div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="onCreate();">SALVA</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="onAddModalClose()">CHIUDI</button>
                </div>
            </div>
        </div>
    </div>


    <!-- Edit user modal -->
    <div class="modal fade" id="editUserModel" tabindex="-1" aria-labelledby="editUserModelLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editUserModelLabel">Modifica dati utente</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onEditModalClose();"></button>
                </div>
                <div class="modal-body">
                    <form class="needs-validation" novalidate>
                        <!-- Role and username -->
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <div class="mb-3" style="width: 150px;">
                                <label for="role" class="form-label">Ruolo</label>
                                <select class="form-select" aria-label="role" :class="v3$.role.$error ? 'is-invalid' : ''" @change="onChange($event);" v-model="editUserForm.role">
                                    <option selected value="admin">admin</option>
                                    <option value="user">user</option>
                                </select>
                                <div class="invalid-feedback">Selezionare un ruolo</div>
                            </div>
                            <div class="mb-3">
                                <label for="username" class="form-label">Username</label>
                                <input type="text" class="form-control" id="username" v-model="editUserForm.username"
                                    aria-describedby="username" required :class="v3$.username.$error ? 'is-invalid' : ''" disabled>
                                <div class="invalid-feedback">Inserire la username</div>
                            </div>
                        </div>
                        <!-- Name -->
                        <div class="mb-3">
                            <label for="name" class="form-label">Nome</label>
                            <input type="text" class="form-control" id="name" v-model="editUserForm.name"
                                aria-describedby="name" required :class="v3$.name.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire il nome</div>
                        </div>
                        <!-- Email -->
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" id="email" v-model="editUserForm.email" 
                                aria-describedby="email" required :class="v3$.email.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire indirizzo E-mail</div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="onEdit(selectedItem);">SALVA</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="onEditModalClose();">CHIUDI</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="editPasswordModal" tabindex="-1" aria-labelledby="editPasswordModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editPasswordModalLabel">Modifica password</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onEditPwdClose();"></button>
                </div>
                <div class="modal-body">
                    <form class="needs-validation" novalidate>
                        <div class="mb-3">
                            <label for="password2" class="form-label">Password</label>
                            <input type="text" class="form-control" id="password2" v-model="editPwdForm.password" @keyup.enter="editPwd(selectedItem);"
                                aria-describedby="password2" required :class="v2$.password.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire la password</div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="editPwd(selectedItem);">SALVA</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="onEditPwdClose();">CHIUDI</button>
                </div>
            </div>
        </div>
    </div>


    <!-- Delete user modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deleteModalLabel">Cancella utente</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p style="font-weight: bold;">Vuoi cancellare l'utente selezionato?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" @click="onDelete(selectedItem);">CANCELLA</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">CHIUDI</button>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
    @import 'datatables.net-dt';

    .appbar { background-color: #03a9f4; }
    .appbar-title {
        font-weight: bold;
        color: white;
    }
    .center {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .icon { cursor: pointer; }
    .icon:hover { transform: scale(1.3); }

    .header {
        margin-top: 30px;
        .btn { margin-bottom: 15px; }
    }

    .spinner-center {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #03a9f4;;
    }

    /* DATATABLE */
    .datatable { width: 70% !important; }
    ::v-deep .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
        margin-bottom: 10px;
    }

    .headers {
        background-color: black;
        color: white
    }
</style>