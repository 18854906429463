<script setup>
    import { ref, defineProps } from 'vue';
    import {required } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core';
    import OverlayLoader from '@/components/OverlayLoader.vue';
    import CategoryApiService from '@/services/CategoryApiService';


    // **** VARIABLES ****
    const categoryApiService = new CategoryApiService();                // Api service
    const props = defineProps(['parentId']);                            // Data from parent component
    const showOverlay = ref(false);                                     // Show / Hide overlay with circular loader
    const formData = ref({ id_parent: null, descr: null, icon: null});  // Form data
    const rules = { id_parent: {}, descr: { required }, icon: {} };     // FE validation rules
    const v$ = useVuelidate(rules, formData);                           // Vuelidate validator
    const categoryIconInput = ref(null);                                // File input ref


    // **** FUNCTIONS ****
    // **************************************************
    // Upload icon preview
    function displaySelectedImage(elementId) {
        const selectedImage = document.getElementById(elementId);
        const fileInput = event.target;
        if (fileInput.files && fileInput.files[0]) {
            const reader = new FileReader();
            reader.onload = function(e) { selectedImage.src = e.target.result; };
            reader.readAsDataURL(fileInput.files[0]);
            formData.value.icon = fileInput.files[0];
        }
        else {
            selectedImage.src = require('../assets/empty-icon.png');
        }
    }


    // **************************************************
    // Add new category
    async function addNewCategory() {
        const isValid = await v$.value.$validate();
        if (isValid) {
            showOverlay.value = true;

            // Set parentId
            const parentId = props.parentId;
            if (parentId)
                formData.value.id_parent = parentId;

            // Perform api
            const retVal = await categoryApiService.createNewCategory(formData.value);
            if (retVal.status == 200) {
                showOverlay.value = false;
                location.reload();
            }
            else {
                showOverlay.value = false;
                const backdrop = document.querySelector('.modal-backdrop');
                backdrop.classList.remove('modal-backdrop', 'fade', 'show');
            }
        }
    }


    // **************************************************
    // Reset form values on modal close
    function onClose() {
        formData.value.id_parent = null;
        formData.value.descr = null;
        formData.value.icon = null;
        displaySelectedImage('selectedAvatar');
        if (categoryIconInput.value)
            categoryIconInput.value.value = '';
    }
</script>


<template>
    <div class="modal fade" id="addCategoryModal" tabindex="-1" aria-labelledby="addCategoryModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addCategoryModalLabel">Crea categoria</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onClose();"></button>
                </div>
                <div class="modal-body">
                    <form class="needs-validation" novalidate @submit.prevent="addNewCategory();">
                        <!-- Description -->
                        <div class="mb-3">
                            <label for="description" class="form-label">Descrizione categoria</label>
                            <input type="text" class="form-control" id="description" v-model="formData.descr" aria-describedby="description" required :class="v$.descr.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire una descrizione</div>
                        </div>

                        <!-- Icon upload -->
                        <div class="row-flex">
                            <div class="column-flex" style="margin-right: 20px;">
                                <img id="selectedAvatar" src="@/assets/empty-icon.png" style="width: 50px; height: 50px; object-fit: cover;"/>
                            </div>
                            <div class="mb-3">
                                <label for="formIcon" class="form-label">Seleziona una icona</label>
                                <input class="form-control" type="file" id="formIcon" accept="image/*" ref="categoryIconInput"
                                    @change="displaySelectedImage('selectedAvatar')">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-outline-primary" @click="addNewCategory();">SALVA</button>
                    <button id="close" type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="onClose();">CHIUDI</button>
                </div>
            </div>
        </div>

        <OverlayLoader v-if="showOverlay"/>
    </div>
</template>


<style scoped>
    /* FLEX */
    .row-flex {
        display: flex;
        flex-direction: row;
    }
    .column-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>