<script setup>
    import { defineProps, defineEmits, ref, watch } from 'vue';
    import {required } from '@vuelidate/validators'
    import { apiBaseUrl } from '@/config/config';
    import useVuelidate from '@vuelidate/core';
    import OverlayLoader from '@/components/OverlayLoader.vue';
    import CategoryApiService from '@/services/CategoryApiService';
    

    // **** VARIABLES ****
    const categoryApiService = new CategoryApiService();                                    // Api service
    const props = defineProps(['item']);                                                    // Data from parent-to-child
    const emits = defineEmits(['close-dialog']);                                            // Data from child-to-parent
    const showOverlay = ref(false);                                                         // Show / Hide overlay with circular loader
    const editCategoryForm = ref({ id: null, id_parent: null, descr: null, icon: null });   // Form data
    const editCategoryRules = { id: {}, id_parent: {}, descr: { required }, icon: {} };     // FE validation rules
    const v$ = useVuelidate(editCategoryRules, editCategoryForm);                           // Vuelidate validator
    const editCategoryIcon = ref(null);                                                     // File input ref

    
    // **** FUNCTIONS ****
    // **************************************************
    // Form data population
    watch(props, () => { 
        if (props.item) {
            editCategoryForm.value.id = props.item.id;
            editCategoryForm.value.id_parent = props.item.id_parent;
            editCategoryForm.value.descr = props.item.descr;
            editCategoryForm.value.icon = props.item.icon;
            if (editCategoryIcon.value)
                editCategoryIcon.value.value = '';
            }
    });


    // **************************************************
    // Perform edit api
    async function onEdit() {
        const isValid = await v$.value.$validate();
        if (isValid) {
            showOverlay.value = true;

            // Manage input data
            const putData = {
                'descr': editCategoryForm.value.descr,
                'icon': (typeof editCategoryForm.value.icon == 'string') ? null : editCategoryForm.value.icon
            };
    
            // Call api and page reload
            const retVal = await categoryApiService.editCategory(putData, editCategoryForm.value.id);
            if (retVal.status == 201) {
                showOverlay.value = false;
                location.reload();
            }
            else {
                showOverlay.value = false;
                const backdrop = document.querySelector('.modal-backdrop');
                backdrop.classList.remove('modal-backdrop', 'fade', 'show');
            }
        }
    }


    // **************************************************
    // Management of the icon preview
    function displaySelectedImage(elementId) {
        const selectedImage = document.getElementById(elementId);
        const fileInput = event.target;
        if (fileInput.files && fileInput.files[0]) {
            const reader = new FileReader();
            reader.onload = function(e) { selectedImage.src = e.target.result; };
            reader.readAsDataURL(fileInput.files[0]);
            editCategoryForm.value.icon = fileInput.files[0];
        }
        else {
            selectedImage.src = require('../assets/empty-icon.png');
        }
    }
</script>

<template>
    <div class="modal fade" id="editCategoryModal" tabindex="-1" aria-labelledby="editCategoryModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">

                <!-- Header -->
                <div class="modal-header">
                    <h5 class="modal-title" id="editCategoryModalLabel">Modifica categoria</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="emits('close-dialog');"></button>
                </div>

                <!-- Body -->
                <div class="modal-body">
                    <form class="needs-validation" novalidate @submit.prevent="onEdit();">
                        <!-- Description -->
                        <div class="mb-3">
                            <label for="description" class="form-label">Descrizione categoria</label>
                            <input type="text" class="form-control" id="description" v-model="editCategoryForm.descr" 
                                aria-describedby="description" required :class="v$.descr.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire una descrizione</div>
                        </div>

                        <!-- Icon -->
                        <div class="row-flex">
                            <!-- Preview -->
                            <div class="column-flex" style="margin-right: 20px;">
                                <img id="editCatAvatar" style="width: 50px; height: 50px; object-fit: cover;" src="@/assets/empty-icon.png" v-if="!editCategoryForm.icon"/>
                                <img id="editCatAvatar" style="width: 50px; height: 50px; object-fit: cover;" :src="apiBaseUrl + '/' + editCategoryForm.icon" v-if="editCategoryForm.icon"/>
                            </div>
                            <!-- Upload -->
                            <div class="mb-3">
                                <label for="formIcon" class="form-label">Modifica icona (opzionale)</label>
                                <input class="form-control" type="file" id="formIcon" @change="displaySelectedImage('editCatAvatar');" accept="image/*" ref="editCategoryIcon"/>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-outline-primary" @click="onEdit();">SALVA</button>
                    <button id="close" type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="emits('close-dialog');">CHIUDI</button>
                </div>
            </div>
        </div>

        <OverlayLoader v-if="showOverlay"/>
    </div>
</template>


<style scoped>
    .row-flex {
        display: flex;
        flex-direction: row;
    }
    .column-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>