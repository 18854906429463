import { BearerName } from "@/config/config";
import { basicApi } from "@/main";
import { HttpStatusCode } from "axios";

export default class AuthApiService {
    http;

    constructor() {
        this.http = basicApi.http;
    }


    // **************************************************
	async login(username, password) {
		var retData = await this.http.post('/api/auth/login', {username: username, password: password})
			.catch( function (error) { console.log(error.message); throw(error) } );
		if (retData.data.status && retData.data.token) {        
			basicApi.setBearer(retData.data.token);
			localStorage.setItem('role', retData.data.role);
		}

		return retData.data;
	}


    // **************************************************
	async logout(all = false) {
		var retData = await this.http.post('/api/auth/logout', !all ? {} : {all: all})
			.catch( function (error) { console.log(error.message); throw(error) } )
		
		// Clean localStorage
		if (retData.status == HttpStatusCode.Ok) {
			localStorage.removeItem(BearerName);
			localStorage.removeItem('role');
		}

		return retData.data.message;
	}
}