<script setup>
    import { ref } from 'vue';
    import {required } from '@vuelidate/validators';
    import useVuelidate from '@vuelidate/core';
    import router from '@/router';
    import AuthApiService from '../services/AuthApiService';


    // **** VARIABLES ****
    const loginForm = ref({ username: null, password: null });
    const rules = { username: { required }, password: { required } };
    const v$ = useVuelidate(rules, loginForm);
    const errorMessage = ref(false);
    const authApiService = new AuthApiService();


    // **** FUNCTIONS ****
    // **************************************************
    async function onLogin() {
        const isValid = await v$.value.$validate();
        if (isValid) {
            errorMessage.value = false;             // Hide error message
            await authApiService.login(loginForm.value.username, loginForm.value.password)
                .catch(retVal => {
                    if (retVal.response && retVal.response.status == 401) {
                        errorMessage.value = true;  // Show error message
                    }
                })
                .then(x => {
                    // Perform homepage routing
                    if (x && x.status)
                        router.push({name: 'HomePage'});
                })
        }
    }
</script>


<template>
    <section class="vh-100 gradient-custom">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div class="card bg-dark text-white" style="border-radius: 1rem;">
                        <div class="card-body p-5 text-center">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <form class="needs-validation" novalidate>
                                    <!-- Title and Subtitle -->
                                    <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
                                    <p class="text-white-50 mb-5">Inserire 'Username' e 'Password'</p>
                                    <p class="error-message" v-if="errorMessage">Credenziali non valide!</p>

                                    <!-- Username -->
                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="username">Username</label>
                                        <input type="text" id="username" class="form-control form-control-lg" v-model="loginForm.username"
                                            :class="v$.username.$error ? 'is-invalid' : ''" @keyup.enter="onLogin();"/>
                                        <div class="invalid-feedback">Inserire una username</div>
                                    </div>
    
                                    <!-- Password -->
                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="typePasswordX">Password</label>
                                        <input type="password" id="typePasswordX" class="form-control form-control-lg" v-model="loginForm.password"
                                            :class="v$.password.$error ? 'is-invalid' : ''" autocomplete="on" @keyup.enter="onLogin();"/>
                                        <div class="invalid-feedback">Inserire una password</div>
                                    </div>
    
                                    <!-- Login button -->
                                    <button class="btn btn-outline-light btn-lg px-5" @click="onLogin();" onkeydown="console.log('a');" type="button" id="loginBtn">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<style scoped>
    .error-message { color: red; }
</style>