import { basicApi } from "../main";


// ============================================================
export default class CategoryApiService {
    http;

    constructor() {
        this.http = basicApi.http;
    }


    // **************************************************
    // Retrieve the list of categories and audio
    async getList(id = null) {
        var retData = await this.http.get((id) ? '/api/category/categoryList/' + id : '/api/category/categoryList')
            .catch(function (error) { 
                console.log(error.message); 
                return { data: {categories: [], audio: [] } } 
            });

        return retData; 
    }


    // **************************************************
    // Retrieve parent category name
    async getCategoryName(id) {
        var retData = await this.http.get('/api/category/name/' + id)
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });

        return retData.data; 
    }


    // **************************************************
    // Create new category (possibly under a parent category)
    async createNewCategory(data) {
        return await this.http.post('/api/category', data, {headers: { "Content-Type": "multipart/form-data" }})
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });
    }


    // **************************************************
    // Edit an existing category, providing the id
    async editCategory(data, id) {
        return await this.http.post('/api/category/' + id, data, {headers: { "Content-Type": "multipart/form-data" }})
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });
    }


    // **************************************************
    // Delete single category
    async deleteCategory(id) {
        return await this.http.delete('/api/category/' + id)
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });
    }
}