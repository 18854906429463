import { apiBaseUrl, AESKey, BearerName } from "@/config/config";
import { showNotification } from "@/main";
import router from "@/router";
import axios from "axios";
import CryptoJS from 'crypto-js';


// ============================================================
export default class BasicApiManager {
    http;


    // **************************************************
    // Management of axios instance, with interceptors
    constructor() {
        console.log("BasicAxios constructor")

        // Create http instance
        this.http = axios.create({
            baseURL: apiBaseUrl,
            withCredentials: true
        });

        // **** AXIOS INTERCEPTORS ****
        // Request interceptor
        this.http.interceptors.request.use(
            (config) => {
                var encryptedToken = localStorage.getItem(BearerName);
                if (encryptedToken !== null) {
                    var bearer = CryptoJS.AES.decrypt(encryptedToken, AESKey).toString(CryptoJS.enc.Utf8);
                    config.headers['Authorization'] =  `Bearer ${bearer}`;
                }
                return (config);
            },
            (error) => {
                console.log(error);
                return Promise.reject(error.response);
            }
        );

        // Response interceptor
        this.http.interceptors.response.use(
			(response) => {
				console.log("From server: ", response);
				return Promise.resolve(response);
			},
			(error) => {
                if (error.response && error.response.status == 401) {
                    console.log("Error 401: ", error.response.statusText);
                    showNotification.value = true;                                                          // Show notification error
                    localStorage.clear();                                                                   // LocalStorage cleanup
					router.replace({ path: "/login", query: { redirect: router.currentRoute.fullPath } });  // Routing to login page
                    if (!(error.response.data && error.response.data.message == "Username & Password does not match with our record.")) {
                        return Promise.resolve({});
                    }
				}
				return Promise.reject(error);
			}          
        )
    }


    // ******************************************
    // Save encrypted bearer token inside localStorage
	setBearer (bearer) {
		var encryptedBearer = CryptoJS.AES.encrypt(bearer, AESKey).toString();
		localStorage.setItem(BearerName, encryptedBearer);
	}
}