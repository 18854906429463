<script setup>
    import { onBeforeMount, ref, inject } from 'vue';
    import { apiBaseUrl } from "@/config/config";
    import router from '@/router';
    import AppbarDropdown from '@/components/AppbarDropdown.vue';
    import AudioApiService from '@/services/AudioApiService';


    // **** VARIABLES **** 
    const audioApiService = new AudioApiService();
    const details = ref(null);
    function back() { router.go(-1); }


    // **************************************************
    // Registers a hook to be called right before the component is to be mounted
    onBeforeMount(async () => {
        const retVal = await audioApiService.getAudioPath(router.currentRoute.value.params.id);
        if (retVal.status == 200) {
            details.value = retVal.data;
        }
    });
</script>


<template>
    <div v-if="details">
    <!-- Appbar -->
    <nav class="navbar appbar">
        <div class="container-fluid">
            <a class="navbar-brand appbar-title">
                <img src="@/assets/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
                ROTARY PODCAST
            </a>

            <AppbarDropdown/>
        </div>
    </nav>

        <!-- Back button -->
        <button type="button" class="btn btn-outline-dark btn" @click="back();" v-if="router.currentRoute.value.fullPath != '/'">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-90deg-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.854 1.146a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L4 2.707V12.5A2.5 2.5 0 0 0 6.5 15h8a.5.5 0 0 0 0-1h-8A1.5 1.5 0 0 1 5 12.5V2.707l3.146 3.147a.5.5 0 1 0 .708-.708l-4-4z"/>
            </svg>
            Back
        </button>

        <!-- Audio player -->
        <div class="audio-container">
            <div v-if="details.icon">
                <img v-bind:src="apiBaseUrl + '/' + details.icon" height="150" width="150">
            </div>    

            <div style="height: 30px;"></div>

            <div class="text-container">
                <h1>{{ details.descr }}</h1>
            </div>

            <audio controls id="myAudio">
                <source v-bind:src="apiBaseUrl + '/' + details.url" type="audio/mpeg">
            </audio>

            <div style="height: 30px;"></div>      
        </div>
    </div>
    <div class="spinner-border center" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
    </div>
</template>


<style scoped>
    .appbar { background-color: #03a9f4;  /* Lightblue like flutter */ }
    .appbar-title {
        font-weight: bold;
        color: white;
    }

    .btn { 
        margin-left: 30px;
        margin-top: 30px;
    }

    .center {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #03a9f4;;
    }

    .audio-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
        min-height: 370px;
    }

    .background {
        background-image: linear-gradient(#144771 , #071a2c);
    }

    .text-container {
      width: 500px;
      padding: 10px;
      height: auto;
      overflow: hidden;
      text-align: center;
    }
</style>