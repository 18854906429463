<script setup>
    import { defineProps, defineEmits, ref, watch } from 'vue';
    import {required } from '@vuelidate/validators'
    import { apiBaseUrl } from '@/config/config';
    import useVuelidate from '@vuelidate/core';
    import OverlayLoader from '@/components/OverlayLoader.vue';
    import AudioApiService from '@/services/AudioApiService';
    

    // **** VARIABLES ****
    const audioApiService = new AudioApiService();                                                  // Api service
    const props = defineProps(['item']);                                                            // Data from parent component
    const emits = defineEmits(['close-dialog']);                                                    // Data from child-to-parent
    const showOverlay = ref(false);                                                                 // Show / Hide overlay with circular loader
    const editAudioForm = ref({ id: null, id_category: null, descr: null, icon: null, file: null}); // Form data
    const editAudioRules = { id: {}, id_category: {}, descr: { required }, icon: {}, file: {} };    // FE validation rules
    const v$ = useVuelidate(editAudioRules, editAudioForm);                                         // Vuelidate validator
    
    // File input refs
    const audioEditRef = ref(null);
    const iconEditRef = ref(null);

    
    // **** FUNCTIONS ****
    // **************************************************
    // Form data population
    watch(props, () => { 
        if (props.item) {
            editAudioForm.value.id = props.item.id;
            editAudioForm.value.id_category = props.item.id_category;
            editAudioForm.value.descr = props.item.descr;
            editAudioForm.value.icon = props.item.icon;
            editAudioForm.value.file = null;
            if (audioEditRef.value)
                audioEditRef.value.value = '';
            if (iconEditRef.value)
                iconEditRef.value.value = '';
        }
    });


    // **************************************************
    // Perform edit api
    async function onEdit() {
        const isValid = await v$.value.$validate();
        if (isValid) {
            showOverlay.value = true;

            // Manage input data
            const putData = {
                'descr': editAudioForm.value.descr,
                'icon': (typeof editAudioForm.value.icon == 'string') ? null : editAudioForm.value.icon,
                'file': editAudioForm.value.file
            };
    
            // Call api and page reload
            const retVal = await audioApiService.editAudio(putData, editAudioForm.value.id);
            if (retVal.status == 201) {
                showOverlay.value = false;
                location.reload();
            }
            else {
                showOverlay.value = false;
                const backdrop = document.querySelector('.modal-backdrop');
                backdrop.classList.remove('modal-backdrop', 'fade', 'show');
            }
        }
    }


    // **************************************************
    // Management of the icon preview
    function displaySelectedImage(elementId) {
        const selectedImage = document.getElementById(elementId);
        const fileInput = event.target;
        if (fileInput.files && fileInput.files[0]) {
            const reader = new FileReader();
            reader.onload = function(e) { selectedImage.src = e.target.result; };
            reader.readAsDataURL(fileInput.files[0]);
            editAudioForm.value.icon = fileInput.files[0];
        }
        else {
            selectedImage.src = require('../assets/empty-icon.png');
        }
    }


    // **************************************************
    function fileAudioManagement() {
        const fileInput = event.target;
        editAudioForm.value.file = (fileInput.files && fileInput.files[0]) ? fileInput.files[0] : null; 
    }
</script>

<template>
    <div class="modal fade" id="editAudioModal" tabindex="-1" aria-labelledby="editAudioModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <!-- Header -->
                <div class="modal-header">
                    <h5 class="modal-title" id="editAudioModalLabel">Modifica Audio</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="emits('close-dialog');"></button>
                </div>
                <div class="modal-body">
                    <form class="needs-validation" novalidate @submit.prevent="onEdit();">
                        <!-- Description -->
                        <div class="mb-3">
                            <label for="description" class="form-label">Descrizione audio</label>
                            <input type="text" class="form-control" id="description" v-model="editAudioForm.descr" aria-describedby="description" 
                                required :class="v$.descr.$error ? 'is-invalid' : ''">
                            <div class="invalid-feedback">Inserire una descrizione</div>
                        </div>

                        <!-- Audio file -->
                        <div class="mb-3">
                            <label for="formIcon" class="form-label">Seleziona file audio (opzionale)</label>
                            <input class="form-control" type="file" id="formFile" required ref="audioEditRef"
                                :class="v$.file.$error ? 'is-invalid' : ''" @change="fileAudioManagement()" accept="audio/*">
                            <div class="invalid-feedback">Inserire un file audio</div>
                        </div>

                        <!-- Icon -->
                        <div class="row-flex">
                            <!-- Preview -->
                            <div class="column-flex" style="margin-right: 20px;">
                                <img id="editAudioAvatar" style="width: 50px; height: 50px; object-fit: cover;" src="@/assets/empty-icon.png" v-if="!editAudioForm.icon"/>
                                <img id="editAudioAvatar" style="width: 50px; height: 50px; object-fit: cover;" :src="apiBaseUrl + '/' + editAudioForm.icon" v-if="editAudioForm.icon"/>
                            </div>
                            <!-- Upload -->
                            <div class="mb-3">
                                <label for="formIcon" class="form-label">Modifica icona (opzionale)</label>
                                <input class="form-control" type="file" id="formIcon" @change="displaySelectedImage('editAudioAvatar');" accept="image/*" ref="iconEditRef"/>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-outline-primary" @click="onEdit();">SALVA</button>
                    <button id="close" type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="emits('close-dialog');">CHIUDI</button>
                </div>
            </div>
        </div>

        <OverlayLoader v-if="showOverlay"/>
    </div>
</template>


<style scoped>
    .row-flex {
        display: flex;
        flex-direction: row;
    }
    .column-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>