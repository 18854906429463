<script setup>
    import { onBeforeMount, ref, watch } from 'vue';
    import { apiBaseUrl } from '@/config/config';
    import AddCategoryModal from '../modals/AddCategoryModal.vue';
    import EditCategoryModal from '../modals/EditCategoryModal.vue';
    import AddAudioModal from '../modals/AddAudioModal.vue';
    import EditAudioModal from '../modals/EditAudioModal.vue';
    import DeleteModal from '../modals/DeleteModal.vue';
    import AppbarDropdown from '@/components/AppbarDropdown.vue';
    import router from '@/router';
    import CategoryApiService from '@/services/CategoryApiService';
    import MainButtonArea from '@/components/MainButtonArea.vue';


    // **** VARIABLES ****
    const categoryApiService = new CategoryApiService();    // Api service
    const circularLoader = ref(false);                      // Show / Hide circular spinner
    const categories = ref([]);                             // List of category
    const audios = ref([]);                                 // List of audio
    const selectedItem = ref(null);
    const parentCat = ref("");                              // Parent category name


    // **** FUNCTIONS ****
    // **************************************************
    onBeforeMount(async () => { await treePopulation(); });
    watch(
        () => router.currentRoute.value,
        async (newRoute, oldRoute) => { await treePopulation(); }
    )


    // **************************************************
    // Tree population
    async function treePopulation() {
        circularLoader.value = true;                        // Show circular loader

        // Retrieve category name
        var parentId = router.currentRoute.value.params.id;
        if (parentId && parentId != undefined) {
            parentCat.value = await categoryApiService.getCategoryName(parentId);
        }

        // List of categories and audios
        var retVal = await categoryApiService.getList(!router.currentRoute.value.params.id ? null : router.currentRoute.value.params.id);
        manageApiData(retVal.data);                     // Api get-data management
        circularLoader.value = false;                   // Hide circular loader
    }


    // **************************************************
    // Manage data from api: list cleanup and re-population
    function manageApiData(retVal) {
        // Categories
        categories.value = [];
        if (retVal.categories)
            retVal.categories.forEach(item => { categories.value.push(item); });

        // Audio
        audios.value = [];
        if (retVal.audio)
            retVal.audio.forEach(item => { audios.value.push(item); });
    }


    // **** DIALOGS MANAGEMENT ****
    // **************************************************
    function onDialogClose() { selectedItem.value = null; }
    function editItem(item) { selectedItem.value = item; }
    function deleteItem(item, isCategoroy) {
        selectedItem.value = item;
        selectedItem.value['category'] = isCategoroy;
    } 


    // **** ROUTING ****
    // **************************************************
    // Management of clicks on a category / audio and routing
    function categoryItemClick(id) {
        if (router.currentRoute.value.params.id != id) {
            circularLoader.value = true;                    // Show circular loader
            router.push({path: '/category/' + id});         // Page routing
        }
    }
    function categoryAudioClick(id) {
        router.push({path: '/podcast/player/' + id});
    }
    function back() {
        circularLoader.value = true;                        // Show circular loader
        router.go(-1);                                      // Go back in history
    }
</script>


<template>
    <!-- Appbar -->
    <nav class="navbar appbar">
        <div class="container-fluid">
            <a class="navbar-brand appbar-title">
                <img src="@/assets/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
                ROTARY PODCAST
            </a>

            <AppbarDropdown/>
        </div>
    </nav>

    <!-- List of items and button area -->
    <div v-if="!circularLoader" style="min-width: 600px;">
        <MainButtonArea :name="parentCat" @back="back()"/>
        
        <div class="row-flex"> <!-- DELETE class="row-flex" FOR COLUMN -->
            <!-- Categories -->
            <div v-if="categories.length > 0" class="main-div">
                <h1>CATEGORIE</h1>
                <ul class="list-group">
                    <div v-for="cat in categories" :key="cat.id" class="item-spacing">
                        <a class="list-group-item list-group-item-action item" @click="categoryItemClick(cat.id)">
                            <div class="row-flex" style="justify-content: space-between;">
                                <div class="column-flex">
                                    <div class="row-flex">
                                        <!-- Icon -->
                                        <template v-if="cat.icon">
                                            <img v-bind:src="apiBaseUrl + '/' + cat.icon" height="22" width="22">
                                        </template>
                                        <template v-else>
                                            <i class="bi bi-folder" style="font-size: 22px;"></i>
                                        </template>
                    
                                        <div style="width: 10px;"></div>
                    
                                        <!-- Description -->
                                        <div style="display: flex; flex-direction: column; justify-content: center;">
                                            {{ cat.descr }}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-outline-primary btn-sm list-btn" data-bs-toggle="modal" data-bs-target="#editCategoryModal" @click.stop="editItem(cat);">
                                        <i class="bi bi-pencil-fill"></i>
                                        Edit
                                    </button>
                                    <button type="button" class="btn btn-outline-danger btn-sm list-btn" data-bs-toggle="modal" data-bs-target="#deleteModal" @click.stop="deleteItem(cat, true);">
                                        <i class="bi bi-trash"></i>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </a>
                    </div>
                </ul>
            </div>
    
            <!-- Audio -->
            <div v-if="audios.length > 0" class="main-div">
                <h1>AUDIO</h1>
                <ul class="list-group">
                    <div v-for="a in audios" :key="a.id" class="item-spacing">
                        <a class="list-group-item list-group-item-action item" @click="categoryAudioClick(a.id);">
                            <div class="row-flex" style="justify-content: space-between;">
                                <div class="column-flex">
                                    <div class="row-flex">
                                        <!-- Icon -->
                                        <template v-if="a.icon">
                                            <img v-bind:src="apiBaseUrl + '/' + a.icon" height="22" width="22">
                                        </template>
                                        <template v-else>
                                            <i class="bi bi-file-earmark-music" style="font-size: 22px;"></i>
                                        </template>
                    
                                        <div style="width: 10px;"></div>
                    
                                        <!-- Description -->
                                        <div style="display: flex; flex-direction: column; justify-content: center;">
                                            {{ a.descr }}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-outline-primary btn-sm list-btn" data-bs-toggle="modal" data-bs-target="#editAudioModal" @click.stop="editItem(a);">
                                        <i class="bi bi-pencil-fill"></i>
                                        Edit
                                    </button>
                                    <button type="button" class="btn btn-outline-danger btn-sm list-btn" data-bs-toggle="modal" data-bs-target="#deleteModal" @click.stop="deleteItem(a, false);">
                                        <i class="bi bi-trash"></i>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </a>
                    </div>
                </ul>
            </div>
            <!-- Empty list -->
            <div v-if="categories.length == 0 && audios.length == 0" class="main-div">
                <p>Nessun dato disponibile</p>
            </div>
        </div>
    </div>
    <div class="spinner-border center" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
    </div>

    <!-- Modals -->
    <AddCategoryModal :parentId="router.currentRoute.value.params.id"/>
    <EditCategoryModal :item="selectedItem" @close-dialog="onDialogClose();"/>
    <AddAudioModal :parentId="router.currentRoute.value.params.id"/>
    <EditAudioModal :item="selectedItem" @close-dialog="onDialogClose();"/>
    <DeleteModal :item="selectedItem"/>
</template>


<style scoped>
    @import '../assets/overlay.css';


    /* APPBAR */
    .appbar { background-color: #03a9f4;  /* Lightblue like flutter */ }
    .appbar-title {
        font-weight: bold;
        color: white;
    }

    /* FLEX */
    .row-flex {
        display: flex;
        flex-direction: row;
    }
    .column-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .button-area {
        margin-left: 30px;
        margin-top: 30px;
    }

    .main-div {
        margin: 30px 30px 30px 30px;
        flex: 1
    }

    .item:hover { cursor: pointer; }

    .center {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #03a9f4;;
    }

    .list-btn {
        margin-left:15px;
    }

    .item-spacing { margin-bottom: 20px; }
</style>