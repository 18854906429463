import { basicApi } from "@/main";


// ============================================================
export default class AudioApiService {
    http;

    constructor() {
        this.http = basicApi.http;
    }


    // **************************************************
    // Retrieve the audio details by id
    async getAudioPath(id) {
        return await this.http.get('/api/audio/audioPath/' + id)
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });
    }


    // **************************************************
    // Create a new audio item
    async createNewAudio(data) {
        return await this.http.post('/api/audio', data, {headers: { "Content-Type": "multipart/form-data" }})
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });
    }


    // **************************************************
    // Edit an existing audio, providing the id
    async editAudio(data, id) {
        return await this.http.post('/api/audio/' + id, data, {headers: { "Content-Type": "multipart/form-data" }})
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });
    }


    // **************************************************
    // Delete audio by id
    async deleteAudio(id) {
        return await this.http.delete('/api/audio/' + id)
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });
    }
}